// @ts-nocheck
import axios from 'axios';
import React, { useState, useEffect, useContext, createContext } from 'react'
import URLS from '../utils/app-url';

export const GetProfileContextHook = createContext();

export const useGetProfileContextHook = () => {
    return useContext(GetProfileContextHook)
}

export default function GetProfileContext({ children }) {
    const [isLoading, setIsLoading] = useState();
    const [profileData, setProfileData] = useState({});
    const [data, setData] = useState([]); // This is for the user dashboard section

    const getUserDetails = async () => {
        setIsLoading(true)
        try {
            setIsLoading(true)

            const apiResponse = await fetch(URLS.getProfile, {
                "method": 'GET',
                headers: {
                    "Access-Control-Allow-Origin": '*',
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("jwt")}`
                }
            })

            const apiResponse2 = await apiResponse.json()

            if (apiResponse2.status == '200') {
                setIsLoading(false)
                // setIsLoggedIn(true)
                // setGetProfileDetails(apiResponse2?.data?.role)
                setProfileData(apiResponse2?.data)
                if (apiResponse2?.data?.role == "user") {
                    localStorage.setItem("userFirstName", apiResponse2?.data?.userProfile?.firstName)
                    localStorage.setItem("userLastName", apiResponse2?.data?.userProfile?.lastName)
                }
                if (apiResponse2?.data?.role == "company") {
                    localStorage.setItem("companyNickName", apiResponse2?.data?.companyProfile?.companyNickName)
                    localStorage.setItem("companyName", apiResponse2?.data?.companyProfile?.companyName)
                }
                if (apiResponse2?.data?.role == "driver") {
                    localStorage.setItem("driverFirstName", apiResponse2?.data?.driverProfile?.firstName)
                    localStorage.setItem("driverLastName", apiResponse2?.data?.driverProfile?.lastName)
                }
                // handleShowModal(`${apiResponse2.message}`)
                // setNavigateLink("/userManagement")
                return apiResponse2; // return success object
            }
            else {
                setIsLoading(false)
                // handleShowModal( `${apiResponse2.message}`)
                return
            }
        } catch (error) {
            setIsLoading(false)
        }
    }
    const [response, setResponse] = useState();

    const getProfileDetails = () => {
        axios.get(URLS.getProfile, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then((res) => {
                setResponse(res);
            })
            .catch((err) => {
                setResponse()
            })
    }
    useEffect(() => {
        getProfileDetails();
    }, [])
    const datas = {
        name: 'get profile context name',
        response: response?.data?.data,
        data,
        setData
    };

    return <GetProfileContextHook.Provider value={{ ...datas }}>
        {children}
    </GetProfileContextHook.Provider>
}