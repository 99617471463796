import React, {useContext, createContext, useState, useEffect} from 'react'

export const MapContextHook = createContext();

export const useMapContextHook =()=>{
    return useContext(MapContextHook)
}
export default function MapContext({children}){
  const[lat, setLat] = useState()
  const[lon, setLon] = useState()

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      // console.log("Latitude is :", position.coords.latitude);
      // console.log("Longitude is :", position.coords.longitude);
      setLat(position.coords.latitude)
      setLon(position.coords.longitude)
    });
  }, []);

  const[isFilled, setIsFilled] = useState(false);
  const[isFilledone, setIsFilledone] = useState('');

  const handleSetIsFilled = (data) =>{
    setIsFilled(data)
  }

  const handleSetIsFilledone = (data) =>{
    setIsFilledone(data)
  }

  const data = {
    isFilled,
    handleSetIsFilled,
    isFilledone,
    handleSetIsFilledone,
    lat,
    lon
  }

  return (
    <MapContextHook.Provider value={{...data}}>
      {children}
    </MapContextHook.Provider>
  )
}