// @ts-nocheck
import { createContext, useEffect, useState } from 'react';
import React from 'react'
import URLS from '../utils/app-url';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext();

export default function AuthValidation(props) {
  const { children } = props
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isLoadings, setIsLoading] = useState(false)
  const [getProfileDetails, setGetProfileDetails] = useState('')
  const [apiStatus, setApiStatus] = useState("");
  const [apiDataLogin, setApiDataLogin] = useState("");
  const [userProfileError, setUserProfileError] = useState("");
  const [driverProfileError, setDriverProfileError] = useState("");
  const [trackImgError, setTrackImgError] = useState("")

  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [navigateLink, setNavigateLink] = useState('');
  const handleShowModal = (data) => {
    setModalData(data);
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false)
  };

  //FCM TOKEN Functionality
  const handleSetFcmToken = async () => {
    let notificationToken = localStorage.getItem('firebaseToken')
    try {
      let data = JSON.stringify({
        "fcmDeviceToken": notificationToken,
      })
      const apiResponse = await fetch(URLS.fcmTokenDevice, { "method": 'POST', headers: { "Access-Control-Allow-Origin": '*', Authorization: `Bearer ${localStorage.getItem("jwt")}`, "Content-Type": "application/json" }, body: data })
      const apiResponse2 = await apiResponse.json()
    } catch (error) {
      // console.log("error:", error)
    }
  }
  const handleDeleteFcmToken = async () => {
    let notificationToken = localStorage.getItem('firebaseToken')
    try {
      let data = JSON.stringify({
        "fcmDeviceToken": notificationToken,
      })
      const apiResponse = await fetch(URLS.fcmTokenDevice, { "method": 'DELETE', headers: { "Access-Control-Allow-Origin": '*', Authorization: `Bearer ${localStorage.getItem("jwt")}`, "Content-Type": "application/json" }, body: data })
      const apiResponse2 = await apiResponse.json()
    } catch (error) {
    }
  }

  //FCM TOKEN Functionality
  const handleSignUp = async (email, password, confirmPassword, role) => {
    setIsLoading(true)

    try {
      let data = JSON.stringify({
        "email": email,
        "password": password,
        "confirmPassword": confirmPassword,
        "role": role,
        ...(sessionStorage.getItem('ip-address') ? { "ipAddress": sessionStorage.getItem('ip-address') } : {}),
        ...(sessionStorage.getItem('user-country') ? { "location": sessionStorage.getItem('user-country') } : {}),
        
        // "ipAddress": sessionStorage.getItem('ip-address') ? sessionStorage.getItem('ip-address') : "",
        // "location": sessionStorage.getItem('user-country') ? sessionStorage.getItem('user-country') : "",
      })
      setIsLoading(true)
      const apiResponse = await fetch(URLS.signUpApi, { "method": 'POST', headers: { "Access-Control-Allow-Origin": '*', "Content-Type": "application/json" }, body: data })
      const apiResponse2 = await apiResponse.json()
      setApiStatus(apiResponse2);
      if (apiResponse2.status == 201) {
        // // console.log(apiResponse2, "----------apiResponse2----------");
        handleShowModal(`${apiResponse2.message}`)
        setIsLoading(false)
        setNavigateLink('/login')
        return apiResponse2; // return success object
      }
      else {
        // console.log(apiResponse2.message, 'errrrrrrrrrrr')
        setIsLoading(false)
        handleShowModal(`${apiResponse2.message}`)
        setNavigateLink('/signUp')
        return
      }
    } catch (error) {
      // console.log("err:", error)
      setIsLoading(false)
    }
  }

  const handleLogin = async (email, password) => {
    setIsLoading(true)
    try {
      let data = JSON.stringify({
        "email": email,
        "password": password,
        ...(sessionStorage.getItem('ip-address') ? { "ipAddress": sessionStorage.getItem('ip-address') } : {}),
        ...(sessionStorage.getItem('user-country') ? { "location": sessionStorage.getItem('user-country') } : {}),
        // "ipAddress": sessionStorage.getItem('ip-address'),
        // "location": sessionStorage.getItem('user-country')
      })
      setIsLoading(true)
      const apiResponse = await fetch(URLS.loginApi, { "method": 'POST', headers: { "Access-Control-Allow-Origin": '*', "Content-Type": "application/json" }, body: data })
      const apiResponse2 = await apiResponse.json()
      setApiDataLogin(apiResponse2);
      if (apiResponse2.status == '200') {
        setIsLoading(false)
        setIsLoggedIn(true)
        localStorage.setItem('jwt', `${apiResponse2.data.tokens.access.token}`)
        const token = apiResponse2.data.tokens.access.token
        const decodedToken = JSON.parse(atob(token.split('.')[1]));
        console.log("decodedToken::",decodedToken);
        const expiresIn = decodedToken?.exp
        console.log("expiresIn::",expiresIn);
        localStorage.setItem('tokenExpiration', expiresIn)
        await handleSetFcmToken();
        if (apiResponse2?.data?.user?.role == "company" && apiResponse2?.data?.user?.profileComplete == true) {
          if (localStorage.getItem('navigateLink')) {
            if (localStorage.getItem('navigateLinkState')) {
              return navigate(`/${localStorage.getItem('navigateLink')}`, { state: JSON.parse(localStorage.getItem('navigateLinkState')) })
            } else {
              return navigate(`/${localStorage.getItem('navigateLink')}`);
            }
          }
          return navigate("/companyDashboard");
        }
        else if (apiResponse2?.data?.user?.role == "company" && apiResponse2?.data?.user?.profileComplete == false) {
          return navigate("/companyDashboard");
          // return navigate("/createCompanyProfile");
        }
        else if (apiResponse2?.data?.user?.role == "user" && apiResponse2?.data?.user?.profileComplete == true) {
          if (localStorage.getItem('navigateLink')) {
            if (localStorage.getItem('navigateLinkState')) {
              return navigate(`/${localStorage.getItem('navigateLink')}`, { state: JSON.parse(localStorage.getItem('navigateLinkState')) })
            } else {
              return navigate(`/${localStorage.getItem('navigateLink')}`);
            }
          }
          return navigate("/userDashboard")
        }
        else if (apiResponse2?.data?.user?.role == "user" && apiResponse2?.data?.user?.profileComplete == false) {
          if (localStorage.getItem('navigateLink')) {
            if (localStorage.getItem('navigateLinkState')) {
              return navigate(`/${localStorage.getItem('navigateLink')}`, { state: JSON.parse(localStorage.getItem('navigateLinkState')) });
            } else {
              return navigate(`/${localStorage.getItem('navigateLink')}`);
            }
          }
          return navigate("/userDashboard");
          // return navigate("/createUserProfile");
        }
        else if (apiResponse2?.data?.user?.role == "driver" && apiResponse2?.data?.user?.profileComplete == true) {
          return navigate("/driverDashboard");
        }
        else if (apiResponse2?.data?.user?.role == "driver" && apiResponse2?.data?.user?.profileComplete == false) {
          return navigate("/DriverProfileDetails");
        }
        return apiResponse2;
      }
      else {
        setIsLoading(false)
        handleShowModal(`${apiResponse2.message}`)
        return
      }
    } catch (error) {
      setIsLoading(false)
    }
  }

  const UserProfileLogout = async () => {
    try {
      let data = JSON.stringify({
        "accessToken": localStorage.getItem("jwt"),
      })
      // console.log("data:", data)

      const apiResponse = await fetch(URLS.userLogout, { "method": 'POST', headers: { "Access-Control-Allow-Origin": '*', Authorization: `Bearer ${localStorage.getItem("jwt")}`, "Content-Type": "application/json" }, body: data })
      const apiResponse2 = await apiResponse.json()

      if (apiResponse2.status == '200') {
        await handleDeleteFcmToken()
        localStorage.clear();
        setIsLoading(false)
        handleShowModal(`${apiResponse2.message}`)
        setNavigateLink(`/home`)
        navigate('/home')
        return apiResponse2; // return success object
      }
      else {
        // setLoader(false)
        setIsLoggedIn(true)
        handleShowModal(`${apiResponse2.message}`)
        return
      }
    } catch (error) {
      // console.log(error)
    }
  }

  const [profileData, setProfileData] = useState({});
  const getUserDetails = async () => {
    setIsLoading(true)

    try {
      setIsLoading(true)

      const apiResponse = await fetch(URLS.getProfile, {
        "method": 'GET',
        headers: {
          "Access-Control-Allow-Origin": '*',
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("jwt")}`
        }
      })

      const apiResponse2 = await apiResponse.json()

      if (apiResponse2.status == '200') {
        setIsLoading(false)
        setIsLoggedIn(true)
        setGetProfileDetails(apiResponse2?.data?.role)
        setProfileData(apiResponse2?.data)
        if (apiResponse2?.data?.role == "user") {
          localStorage.setItem("userFirstName", apiResponse2?.data?.userProfile?.firstName)
          localStorage.setItem("userLastName", apiResponse2?.data?.userProfile?.lastName)
        }
        if (apiResponse2?.data?.role == "company") {
          localStorage.setItem("companyNickName", apiResponse2?.data?.companyProfile?.companyNickName)
          localStorage.setItem("companyName", apiResponse2?.data?.companyProfile?.companyName)
        }
        if (apiResponse2?.data?.role == "driver") {
          localStorage.setItem("driverFirstName", apiResponse2?.data?.driverProfile?.firstName)
          localStorage.setItem("driverLastName", apiResponse2?.data?.driverProfile?.lastName)
        }
        // handleShowModal(`${apiResponse2.message}`)
        // setNavigateLink("/userManagement")
        return apiResponse2; // return success object
      }
      else {
        setIsLoading(false)
        // handleShowModal( `${apiResponse2.message}`)
        return
      }
    } catch (error) {
      // console.log("error:", error)
      setIsLoading(false)
    }
  }

  const getUserAvatar = async () => {
    setIsLoading(true)

    try {
      setIsLoading(true)

      const apiResponse = await fetch(URLS.getAvatar, {
        "method": 'GET',
        headers: {
          "Access-Control-Allow-Origin": '*',
          "Content-Type": "application/json",
          // Authorization: `Bearer ${localStorage.getItem("jwt")}`
        }
      })

      const apiResponse2 = await apiResponse.json()

      if (apiResponse2.status == '201') {
        // console.log(apiResponse2, "--------apiResponse2----------apiResponse2");
        setIsLoading(false)
        setIsLoggedIn(true)
        // handleShowModal(`${apiResponse2.message}`)
        return apiResponse2; // return success object
      }
      else {
        setIsLoading(false)
        handleShowModal(`${apiResponse2.message}`)
        return
      }
    } catch (error) {
      // console.log("error:", error)
      setIsLoading(false)
    }
  }

  const getCompanyAvatar = async () => {
    setIsLoading(true)

    try {
      setIsLoading(true)
      const apiResponse = await fetch(URLS.getCompanyAvatar, {
        "method": 'GET',
        headers: {
          "Access-Control-Allow-Origin": '*',
          "Content-Type": "application/json",
        }
      })

      const apiResponse2 = await apiResponse.json()

      if (apiResponse2.status == '201') {
        setIsLoading(false)
        setIsLoggedIn(true)
        return apiResponse2; // return success object
      }
      else {
        setIsLoading(false)
        handleShowModal(`${apiResponse2.message}`)
        return
      }
    } catch (error) {
      // console.log("error:", error)
      setIsLoading(false)
    }
  }

  const handleCreateUserProfile = async (useAvatar, nickname, firstName, middleName, lastName, dob, countryList, mobileNo, faxNo, telephone, gender, companyName, companyTaxId, euriNumber, address, zipcode, city, country, companyAddress, companyZipcode, companyCity, companyCountry, weightUnit, lengthUnit, Currency) => {
    setIsLoading(true)

    try {
      let data = JSON.stringify({
        "imgUrl": useAvatar,
        "userNickName": nickname,
        "firstName": firstName,
        "middleName": middleName,
        "lastName": lastName,
        "DOB": dob,
        "mobile": `${countryList} ${mobileNo}`,
        "faxNo": faxNo,
        "telephoneNo": telephone,
        "gender": gender,
        "companyName": companyName,
        "companyTaxId": companyTaxId,
        "euriNumber": euriNumber,
        "address": address,
        "zipCode": zipcode,
        "city": city,
        "country": country,
        "companyAddress": companyAddress,
        "companyZipCode": companyZipcode,
        "companyCity": companyCity,
        "companyCountry": companyCountry,
        "weightUnit": weightUnit,
        "lengthUnit": lengthUnit,
        "Currency": Currency,
        "ipAddress": sessionStorage.getItem('ip-address'),
        "location": sessionStorage.getItem('user-country')
      })

      // console.log("user profiledata:", data)
      setIsLoading(true)

      const apiResponse = await fetch(URLS.userProfile, {
        "method": 'PATCH',
        headers: {
          "Access-Control-Allow-Origin": '*',
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("jwt")}`
        }, body: data
      })

      const apiResponse2 = await apiResponse.json()

      setUserProfileError(apiResponse2);
      if (apiResponse2.status == '201') {
        // console.log(apiResponse2, "--USERCREATEPROFILE---");
        setIsLoading(false)
        setIsLoggedIn(true)
        setNavigateLink("/userProfilesDetails")
        handleShowModal(`${apiResponse2.message}`)
        return apiResponse2; // return success object
      }
      else {
        setIsLoading(false)
        handleShowModal(`${apiResponse2.message}`)
        // console.log("error error error error key", apiResponse2.keys);
        // console.log("error error error error", apiResponse2.message);
        return
      }
    } catch (error) {
      // console.log("error:", error)
      setIsLoading(false)
    }
  }

  const handleCreateCompanyProfile = async (useAvatar, nickname, DetailsInformation, companyName, companyEmployes, registerationNumber, comapanyExtSince, TaxIdentificationNumber, companyTelePhoneNO,ownerFirstName, ownerMiddleName, ownerLastName, OwnerDOB, countryList, mobile, faxNo, telephoneNo, ownerDesignation, Ownergender, address, zipCode, city, country, sameAsPersonal, BillingAddress, BillingZipCode, BillingCity, BillingCountry, weightUnit, lengthUnit, Currency, DriverUnit, vehicleType, vehicleModel, vehicleNo, licenceNo) => {
    setIsLoading(true)
    console.log("aaaya:::");
    try {
    console.log("aaaya:::");
    console.log("DetailsInformation:::",DetailsInformation);

      let data = JSON.stringify({
        "companyLogoImageUrl": useAvatar,
        "companyNickName": nickname,
        "DetailsInformation": DetailsInformation,
        ... (DetailsInformation === false ?
          {
            "companyName": companyName,
            "NumberofEmployees": companyEmployes,
            "registerationNumber": registerationNumber,
            "comapanyExtSince": comapanyExtSince,
            "TaxIdentificationNumber": TaxIdentificationNumber,
            "companyTelePhoneNO": companyTelePhoneNO,
          } : {
            "ownerFirstName": ownerFirstName,
            "ownerMiddleName": ownerMiddleName,
            "ownerLastName": ownerLastName,
            "OwnerDOB": OwnerDOB,
            "mobile": `${countryList} ${mobile}`,
            "faxNo": faxNo,
            "telephoneNo": telephoneNo,
            "ownerDesignation": ownerDesignation,
            "Ownergender": Ownergender,
          }
        ),
        "address": address,
        "zipCode": zipCode,
        "city": city,
        "country": country,
        "SameCompanyCountryAddress": sameAsPersonal,
        "BillingAddress": BillingAddress,
        "BillingZipCode": BillingZipCode,
        "BillingCity": BillingCity,
        "BillingCountry": BillingCountry,
        "weightUnit": weightUnit,
        "lengthUnit": lengthUnit,
        "Currency": Currency,
        "DriverUnit": DriverUnit,
        ...(DriverUnit == "Company owner as a driver" ? {
          "vehicleType": vehicleType,
          "vehicle_Model": vehicleModel,
          "vehicleNumber": vehicleNo,
          "LicenseNo": licenceNo,

        } : {}),
        ...(sessionStorage.getItem('ip-address') ? { "ipAddress": sessionStorage.getItem('ip-address') } : {}),
        ...(sessionStorage.getItem('user-country') ? { "location": sessionStorage.getItem('user-country') } : {}),
        // "ipAddress": sessionStorage.getItem('ip-address'),
        // "location": sessionStorage.getItem('user-country')
      })

      setIsLoading(true)
      const apiResponse = await fetch(URLS.companyProfile, {
        "method": 'PATCH',
        headers: { 
          "Access-Control-Allow-Origin": '*',
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("jwt")}`
        }, body: data
      })

      const apiResponse2 = await apiResponse.json()

      if (apiResponse2.status) {
        setIsLoading(false)
        setIsLoggedIn(true)
        setNavigateLink("/companyProfileDetails")
        handleShowModal(`${apiResponse2.message}`)
        return apiResponse2; // return success object
      }
      else {
        setIsLoading(false)
        handleShowModal(`${apiResponse2.message}`)
        setNavigateLink("")

      }
    } catch (error) {
      console.log("error:", error)
      setIsLoading(false)
    }
  }

  const handleCreateDriverProfile = async (useAvatar, driverNickName, firstName, middleName, lastName, DOB, countryList, mobileNo, faxNo, telephoneNo, gender, address, zipCode, city, country, vehicleType, vehicleModel, vehicleNo, licenceNo) => {
    setIsLoading(true)

    try {
      let data = JSON.stringify({
        "imgUrl": useAvatar,
        "driverNickName": driverNickName,
        "firstName": firstName,
        "middleName": middleName,
        "lastName": lastName,
        "DOB": DOB,
        "mobileNo": `${countryList} ${mobileNo}`,
        "faxNo": faxNo,
        "telephoneNo": telephoneNo,
        "gender": gender,
        "address": address,
        "zipCode": zipCode,
        "city": city,
        "country": country,
        "vehicleType": vehicleType,
        "vehicle_Model": vehicleModel,
        "vehicleNo": vehicleNo,
        "licenceNo": licenceNo,
        "ipAddress": sessionStorage.getItem('ip-address'),
        "location": sessionStorage.getItem('user-country')
      })
      setIsLoading(true)

      const apiResponse = await fetch(URLS.driverProfile, {
        "method": 'PATCH',
        headers: {
          "Access-Control-Allow-Origin": '*',
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("jwt")}`
        }, body: data
      })

      const apiResponse2 = await apiResponse.json()
      setDriverProfileError(apiResponse2)

      // console.log(apiResponse2, "--USERCREATEPROFILE---");
      if (apiResponse2.status) {
        // console.log(apiResponse2, "--USERCREATEPROFILE---");
        setIsLoading(false)
        setIsLoggedIn(true)
        setNavigateLink("/DriverProfileDetails")
        handleShowModal(`${apiResponse2.message}`)
        return apiResponse2;
      }
      else {
        setIsLoading(false)
        handleShowModal(`${apiResponse2.message}`)
        return
      }
    } catch (error) {
      // console.log("error:", error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getUserDetails()
  }, [])

  const handleStatusFun = async (status, shipmentId, uploadImg) => {
    setIsLoading(true)
    try {
      let data = JSON.stringify({
        "Shipmentstatus": status,
        "BidId": shipmentId,
        ...(status === "loaded" || status === "Delivered" ? { "images": uploadImg } : {}),
        "ipAddress": sessionStorage.getItem('ip-address'),
        "location": sessionStorage.getItem('user-country')
      })
      // console.log("status data:", data)
      setIsLoading(true)

      const apiResponse = await fetch(URLS.shipmentStatus, {
        "method": 'POST',
        headers: {
          "Access-Control-Allow-Origin": '*',
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("jwt")}`
        }, body: data
      })

      const apiResponse2 = await apiResponse.json()
      setTrackImgError(apiResponse2)
      if (apiResponse2.status == "200") {
        // console.log(apiResponse2, "--SHIPMENT STATUS---");
        setIsLoading(false)
        setIsLoggedIn(true)
        // window.location.reload()
        handleShowModal(`${apiResponse2.message}`)
        setNavigateLink('');
        // window.location.reload(true)
        return apiResponse2; // return success object
      }
      else {
        setIsLoading(false)
        handleShowModal(`${apiResponse2.message}`)
        setNavigateLink('');
        return
      }
    } catch (error) {
      // console.log("error:", error)
      setIsLoading(false)
    }
  }

  const handleUserConfirmation = async (bidId) => {
    setIsLoading(true)
    try {
      let data = JSON.stringify({
        "BidId": bidId,

      })
      // // console.log("status data:",data)
      setIsLoading(true)

      const apiResponse = await fetch(URLS.deliveryConfirmation, {
        "method": 'POST',
        headers: {
          "Access-Control-Allow-Origin": '*',
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("jwt")}`
        }, body: data
      })

      const apiResponse2 = await apiResponse.json()
      if (apiResponse2.status == "200") {
        // console.log(apiResponse2, "--user Delivered  STATUS---");
        setIsLoading(false)

        // window.location.reload()
        handleShowModal(`${apiResponse2.message}`)
        setNavigateLink("/userDashboard")
        return apiResponse2; // return success object
      }
      else {
        setIsLoading(false)
        handleShowModal(`${apiResponse2.message}`)
        return
      }
    } catch (error) {
      // console.log("error:", error)
      setIsLoading(false)
    }
  }

  const handleDeliveryStatus = async (bidId) => {
    setIsLoading(true)
    try {
      const apiResponse = await fetch(`${URLS.getdeliveryStatus}/${bidId}`, {
        "method": 'GET',
        headers: {
          "Access-Control-Allow-Origin": '*',
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("jwt")}`
        }
      })

      const apiResponse2 = await apiResponse.json()
      if (apiResponse2.status == "200") {
        setIsLoading(false)
        return apiResponse2; // return success object
      }
      else {
        setIsLoading(false)
        handleShowModal(`${apiResponse2.message}`);
        setNavigateLink('');
        return
      }
    } catch (error) {
      // console.log("error:", error)
      setIsLoading(false)
    }
  }

  const handleReadAllNotification = async () => {
    try {
      const apiResponse = await fetch(URLS.fcmtoken, {
        "method": 'PATCH',
        headers: {
          "Access-Control-Allow-Origin": '*',
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("jwt")}`
        }
      })
      const apiResponse2 = await apiResponse.json()
      if (apiResponse2.status == "200") {
        return apiResponse2; // return success object
      }
      else {
        handleShowModal(`${apiResponse2.message}`)
        setNavigateLink('');
        return
      }
    } catch (error) {
    }
  }

  // Card Details API 
  const handleAddCardDetails = async (cardType, cardNumber, expiryDateMonth, expiryDateYear, cvc, Currency) => {
    setIsLoading(true)
    try {
      let data = JSON.stringify({
        "cardType": cardType,
        "cardNumber": cardNumber,
        "expiryDateMonth": expiryDateMonth,
        "expiryDateYear": expiryDateYear,
        "cvc": cvc,
        "Currency": Currency,
      })
      // console.log("card data:", data)
      setIsLoading(true)

      const apiResponse = await fetch(URLS.registerCard, {
        "method": 'POST',
        headers: {
          "Access-Control-Allow-Origin": '*',
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("jwt")}`
        }, body: data
      })

      const apiResponse2 = await apiResponse.json()
      if (apiResponse2.status == "200") {
        // console.log(apiResponse2, "--card STATUS---");
        setIsLoading(false)

        handleShowModal(`${apiResponse2.message}`)
        setNavigateLink("/userDashboard")
        return apiResponse2; // return success object
      }
      else {
        setIsLoading(false)
        handleShowModal(`${apiResponse2.message}`)
        setNavigateLink('');
        return
      }
    } catch (error) {
      // console.log("error:", error)
      setIsLoading(false)
    }
  }

  // user payment  API 
  const handleUserPaymentDetails = async (BidId, amount, cardType, cardNumber, expiryDateMonth, expiryDateYear, cvc, Currency) => {
    setIsLoading(true)
    try {
      let data = JSON.stringify({
        "BidId": BidId,
        "amount": amount,
        "cardType": cardType,
        "cardNumber": cardNumber,
        "expiryDateMonth": expiryDateMonth,
        "expiryDateYear": expiryDateYear,
        "cvc": cvc,
        "Currency": Currency,
        "ipAddress": sessionStorage.getItem('ip-address'),
        "location": sessionStorage.getItem('user-country')
      })
      setIsLoading(true)

      const apiResponse = await fetch(URLS.payment, {
        "method": 'POST',
        headers: {
          "Access-Control-Allow-Origin": '*',
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("jwt")}`
        }, body: data
      })

      const apiResponse2 = await apiResponse.json()
      if (apiResponse2.status == "200") {
        // console.log(apiResponse2, "--payment STATUS---");
        setIsLoading(false)

        handleShowModal(`${apiResponse2.message}`)
        setNavigateLink("/userDashboard")
        return apiResponse2; // return success object
      }
      else {
        setIsLoading(false)
        handleShowModal(`${apiResponse2.message}`)
        setNavigateLink('');
        return
      }



    } catch (error) {
      // console.log("error:", error)
      setIsLoading(false)
    }
  }

  // POST Chat API
  const handlePostChat = async (message, bidId) => {
    setIsLoading(true)
    try {
      let data = JSON.stringify({
        "message": message,
        "BidId": bidId
      })
      setIsLoading(true)
      const apiResponse = await fetch(URLS.chatSave, {
        "method": 'POST',
        headers: {
          "Access-Control-Allow-Origin": '*',
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("jwt")}`
        }, body: data
      })
      const apiResponse2 = await apiResponse.json()
      if (apiResponse2.status == "200") {
        setIsLoading(false)
        return apiResponse2;
      }
      else {
        setIsLoading(false)
        return
      }
    } catch (error) {
      setIsLoading(false)
    }
  }

  // GET Chat Api
  const handleGetChat = async (BidId) => {
    try {
      setIsLoading(true)
      const apiResponse = await fetch(`${URLS.getChat}/${BidId}`, {
        "method": 'GET',
        headers: {
          "Access-Control-Allow-Origin": '*',
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("jwt")}`
        }
      })
      const apiResponse2 = await apiResponse.json()
      if (apiResponse2.status == "200") {
        setIsLoading(false)
        return apiResponse2; // return success object
      }
      else {
        setIsLoading(false)
        return
      }
    } catch (error) {
      setIsLoading(false)
    }
  }

  return (
    <AuthContext.Provider
      value={{
        handleSignUp,
        handleLogin,
        UserProfileLogout,
        getUserDetails,
        getUserAvatar,
        getCompanyAvatar,
        handleCreateUserProfile,
        handleCreateCompanyProfile,
        handleStatusFun,
        isLoggedIn,
        handleReadAllNotification,
        handleCreateDriverProfile,
        handleDeliveryStatus,
        handleAddCardDetails,
        handleUserPaymentDetails,
        handleUserConfirmation,
        handlePostChat,
        handleGetChat,
        apiStatus,
        apiDataLogin,
        userProfileError,
        driverProfileError,
        trackImgError,
        profileData,
        showModal,
        modalData,
        navigateLink,
        handleCloseModal,
        handleShowModal,
        setNavigateLink
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
