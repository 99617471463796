// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-loader {
    height: 100vh;
    width: 100vw;
    /* background: rgba(82, 73, 73, 0.737); */
    background: rgba(49, 229, 235, 0.09);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
}

/* .main-loader>svg {
    -webkit-animation: icon-spin 2s infinite linear;
    animation: icon-spin 2s infinite linear;
} */

@keyframes icon-spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(359deg);
    }
}`, "",{"version":3,"sources":["webpack://./src/utils/loader.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,yCAAyC;IACzC,oCAAoC;IACpC,eAAe;IACf,MAAM;IACN,OAAO;IACP,YAAY;AAChB;;AAEA;;;GAGG;;AAcH;IACI;QAEI,uBAAuB;IAC3B;;IAEA;QAEI,yBAAyB;IAC7B;AACJ","sourcesContent":[".main-loader {\n    height: 100vh;\n    width: 100vw;\n    /* background: rgba(82, 73, 73, 0.737); */\n    background: rgba(49, 229, 235, 0.09);\n    position: fixed;\n    top: 0;\n    left: 0;\n    z-index: 100;\n}\n\n/* .main-loader>svg {\n    -webkit-animation: icon-spin 2s infinite linear;\n    animation: icon-spin 2s infinite linear;\n} */\n\n@-webkit-keyframes icon-spin {\n    0% {\n        -webkit-transform: rotate(0deg);\n        transform: rotate(0deg);\n    }\n\n    100% {\n        -webkit-transform: rotate(359deg);\n        transform: rotate(359deg);\n    }\n}\n\n@keyframes icon-spin {\n    0% {\n        -webkit-transform: rotate(0deg);\n        transform: rotate(0deg);\n    }\n\n    100% {\n        -webkit-transform: rotate(359deg);\n        transform: rotate(359deg);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
