
let baseAuthUrl = `https://apigateway.logistic-world.com/user-service-api/v1/auth`;
let baseAuthUrl1 = `https://apigateway.logistic-world.com/user-service-api/v1/user`;
let baseNotificationAuth = `https://apigateway.logistic-world.com/user-service-api/v1/fcmToken`
let baseChatUrl = `https://apigateway.logistic-world.com/user-service-api/v1/chat`

// if(window.location.protocol === "http:"){
//     baseAuthUrl = 'http://192.168.0.93:3000/user-service-api/v1/auth'
//     baseAuthUrl1 = 'http://192.168.0.93:3000/user-service-api/v1/user'
//     baseNotificationAuth = 'http://192.168.0.93:3000/user-service-api/v1/fcmToken'
//     baseChatUrl = 'http://192.168.0.93:3000/user-service-api/v1/chat'
// }
// const baseAuthUrl2 = `http://192.168.0.93:3000/admin-service-api/v1/admin`;


export const URLS = {
    signUpApi: `${baseAuthUrl}/register`,
    loginApi: `${baseAuthUrl}/login`,
    logoutApi: `${baseAuthUrl}/logout/`,
    forgotPasswordApi: `${baseAuthUrl}/forgot-password`,
    loginWithSocialMedia :  `${baseAuthUrl}/login-with-socialMedia`,
    changePassword : `${baseAuthUrl}/change-password`,
    currencies : `${baseAuthUrl1}/currencies`,

    uploadImage: `${baseAuthUrl1}/uploadImage`,
    userProfile: `${baseAuthUrl1}/user/profile`,
    getProfile: `${baseAuthUrl1}/getProfile`,
    getAvatar: `${baseAuthUrl1}/userAvatar`,
    userLogout: `${baseAuthUrl}/logout`,
    userUploadSingleImage: `${baseAuthUrl1}/uploadImage`,
    userUploadMultipleImage: `${baseAuthUrl1}/uploadMultipleImage`,
    getCountry: `${baseAuthUrl1}/countries`,
    filterDashboard: `${baseAuthUrl1}/referencePost`,
    referencePost: `${baseAuthUrl1}/referencePost`,
    senderCities: `${baseAuthUrl1}/senderCities`,
    receiverCities: `${baseAuthUrl1}/receiverCities`,
    getradiusdata: `${baseAuthUrl1}/getradiusdata`,
    commodities: `${baseAuthUrl1}/getShipmentDropdowns/main-category`,
    getdeliveryStatus: `${baseAuthUrl1}/getdeliveryStatus`,
    registerCard: `${baseAuthUrl1}/registerCard`,
    payment: `${baseAuthUrl1}/payment`,
    onGoingBids : `${baseAuthUrl1}/getBidlist`,
    notifications : `${baseAuthUrl1}/notifications`,
    getNotifications : `${baseAuthUrl1}/getnotifications`,
    updateTakeAtour : `${baseAuthUrl1}/updateTakeAtour`,

    // invoice 
    getInvoice: (id)=> `${baseAuthUrl1}/user/download-invoice/${id}`,
    getInvoiceForCompany: (id)=> `${baseAuthUrl1}/company/download-invoice/${id}`,

    // chat api
    chatSave: `${baseChatUrl}/chatSave`,
    getChat: `${baseChatUrl}/getChat`,
    getCompanyEmployees : `${baseAuthUrl1}/companyEmployees`,
    getChatMember : `${baseAuthUrl}/getChatMember`,


    // Notification api
    fcmTokenDevice: `${baseNotificationAuth}/fcmDeviceToken`,
    getFcmToken: `${baseNotificationAuth}/getNotifications`,
    fcmtoken: `${baseNotificationAuth}/readAllNotifications`,

    // User api
    userDashboardCount : `${baseAuthUrl1}/userDashboardCount`,
    userShipmentPost: `${baseAuthUrl1}/shipmentPost`,
    updateShipmentPost : `${baseAuthUrl1}/updateShipmentPost`,
    deleteShipmentPost :(id)=> {return `${baseAuthUrl1}/deleteShipmentPost/${id}`},
    allShipment: `${baseAuthUrl1}/allShipment`,
    singleUserShipment: `${baseAuthUrl1}/singleUserShipment`,
    companyBidsList: `${baseAuthUrl1}/comPropOnShipmentForUser`,
    userBidsSingleList: (id)=>`${baseAuthUrl1}/comPropOnShipmentForUser/Single/${id}`,
    companyAcceptBid: `${baseAuthUrl1}/shipmentAwarded`,
    companyDeleteBids: `${baseAuthUrl1}/bidDelete`,
    shipmentStatus: `${baseAuthUrl1}/shipmentChangeStatus`,
    reviewAndRating: `${baseAuthUrl1}/sendReviewAndRatings`,
    recentPostUser: `${baseAuthUrl1}/recentPostsForUser`,
    getAddressFromCoordinates: `${baseAuthUrl1}/getAddressFromCoordinates`,
    deliveryConfirmation: `${baseAuthUrl1}/deliveryConfirmation`,
    getpostalCode: `${baseAuthUrl1}/getpostalCode`,
    getcityName: `${baseAuthUrl1}/getcityName`,
    getTransactions : `${baseAuthUrl1}/getTransactions`,
    companyAlreadyPropToShipment : `${baseAuthUrl1}/companyAlreadyPropToShipment`,
    singleShipmentById : `${baseAuthUrl1}/singleShipment`,




    // company api
    companyDashboardCounts : `${baseAuthUrl1}/companyDashboardCounts`,
    getCompanyAvatar: `${baseAuthUrl1}/companyAvatar`,
    companyProfile: `${baseAuthUrl1}/company/profile`,
    companyPostbid: `${baseAuthUrl1}/comPropToShipment`,
    singleCompanyPostBids: `${baseAuthUrl1}/companyProposalsOnShipment`,
    singleCompanyPostBidsDescription: `${baseAuthUrl1}/companyProposalsOnShipment`,
    recentPost: `${baseAuthUrl1}/recentPosts`,
    companyDriver: `${baseAuthUrl1}/getCompanyDrivers`,
    alignProjectToDriver: `${baseAuthUrl1}/alignProjectToDriver`,
    transferWalletToBank: `${baseAuthUrl1}/transferWalletToBank`,
    companyBalance: `${baseAuthUrl1}/companyBalance`,
    companyKycForPayment : `${baseAuthUrl1}/companyKycForPayment`,
    getRatingsReview : `${baseAuthUrl1}/getRatingsReview`,

    // driver api
    addDriver: `${baseAuthUrl}/sendDriverRegisterMail`,
    registerDriver: `${baseAuthUrl}/register/driver`,
    driverProfile: `${baseAuthUrl1}/driver/profile`,
    getDriverProjects: `${baseAuthUrl1}/getDriverProjects`,

    //chat With Admin
    getChatWithAdmin: `${baseChatUrl}/getUserAdminchat`,
    sendChatWithAdmin: `${baseChatUrl}/userAdminchat`,
    getChatCount: (id)=> {
        return `${baseChatUrl}/unreadChatCount/${id}`
    },
    readChats: (id)=> {
        return `${baseChatUrl}/readAllChats/${id}`
    },
    gettingCategories: `${baseAuthUrl1}/servicescategory`,

    contactToMail: `${baseAuthUrl1}/contact-us`
}
export default URLS;
